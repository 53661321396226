<script setup lang="ts">
import {
  FrontiersImage,
  IFrontiersImageProps
} from "@frontiers-vue3/public-pages";
import { mapAssetToFrontiersImageByMedia } from "~/mappers/components";
import { MainHeaderImageQueries } from "~/const/ImageQueriesByMedia";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { IContentfulAsset } from "~/types/contentful/abstract";

gsap.registerPlugin(ScrollTrigger);

const props = defineProps<{
  imagesMain: IContentfulAsset;
  imagesCollection: Array<IContentfulAsset>;
}>();

const mainHeaderImage = computed((): IFrontiersImageProps => {
  const image = mapAssetToFrontiersImageByMedia(
    props.imagesMain,
    MainHeaderImageQueries,
    false
  );
  return image;
});

const mainHeaderImages = computed((): IFrontiersImageProps[] => {
  const images = props.imagesCollection.map((i) =>
    mapAssetToFrontiersImageByMedia(i, MainHeaderImageQueries, false)
  );
  return images || [mainHeaderImage.value];
});

const randomNumber = Math.floor(Math.random() * mainHeaderImages.value.length);
const randomHeroBanner = ref<IFrontiersImageProps>(
  mainHeaderImages.value[randomNumber]
);

onMounted(() => {
  gsap.to(".HomeMainHeader > .FrontiersImage", {
    yPercent: 117,
    duration: 1,
    ease: "none",
    scrollTrigger: {
      trigger: ".HomeMainHeader",
      start: "top top",
      end: "bottom top",
      scrub: true,
      invalidateOnRefresh: true
    }
  });
});
</script>
<template>
  <header class="HomeMainHeader">
    <FrontiersImage
      class="HomeMainHeader__imgMask"
      :sources="randomHeroBanner.sources"
      :imageAlt="randomHeroBanner.imageAlt" />
    <div class="HomeMainHeader__ibarSpacing"></div>
    <div class="HomeMainHeader__wrapper">
      <h1 class="HomeMainHeader__title">Science news</h1>
    </div>
  </header>
</template>

<style lang="scss">
.HomeMainHeader {
  position: relative;
  overflow: hidden;

  &__ibarSpacing {
    height: var(--ibar-protection-spacing);
  }

  &__wrapper {
    @include generalPageWrapper;
    height: 272px;

    @media #{$break-laptop-sm} {
      height: 220px;
    }

    @media #{$break-md} {
      height: 192px;
    }

    @media #{$break-xs} {
      height: 234px;
    }
  }

  &__imgMask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($blackHex, 0.3);
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center bottom;
    }
  }

  &__title {
    color: $white;
    padding-top: 72px;
    @include f-40-500;

    @media #{$break-laptop-sm} {
      @include f-32;
    }

    @media #{$break-md} {
      @include f-28;
      padding-top: 56px;
    }

    @media #{$break-xs} {
      @include f-24;
      padding-top: 48px;
    }
  }
}
</style>
